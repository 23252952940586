export const parse = (json, fallback = false) => {
  try {
    if (json === null || json === "") {
      return fallback;
    }

    return JSON.parse(json) || fallback;
  } catch (e) {
    console.error(e);
    return fallback;
  }
};
export const _chunks = (array, size = 2) => {
  let arrayChunks = [];
  for (let i = 0; i < array.length; i += size) {
    let arrayChunk = array.slice(i, i + size);
    arrayChunks.push(arrayChunk);
  }
  return arrayChunks;
};

// Creates a range (array) of numbers.
export const range = (integer, start = 0) =>
  [...Array(parseInt(integer)).keys()].map((i) => i + parseInt(start));

// Capitalize a string.
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// abbreviate class name with a prefix
export const _classes = (styles) => (name) => {
  if (typeof name === "string") {
    return styles[name] || name || "";
  }

  if (Array.isArray(name)) {
    return name.map((n) => styles[n] || n || "").join(" ");
  }

  return "";
};

// Limit how often a function fires.
// Great for event listeners like onResize
// delay in ms
export const debounce = (delay, fn) => {
  let time = Date.now();

  return function debounced(e) {
    if (time + delay - Date.now() < 0) {
      fn(e);
      time = Date.now();
    }
  };
};

//sleep
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const starting_price = [3295, 4000, 4500, 5000, 5500];
const createPrices = (end = []) => {
  return [...starting_price, ...end];
};
const base_price = [{ value: -1, label: "any" }];
const min_prices = createPrices([]);
const max_prices = createPrices([6000, 6500]);
export const price_min_options = [
  ...base_price,
  ...min_prices.map((n) => ({ label: `$${n}`, value: n })),
];
export const price_max_options = [
  ...base_price,
  ...max_prices.map((n, index) => ({
    label: index === max_prices.length - 1 ? `$${n}+` : `$${n}`,
    value: n,
  })),
];
// const starting_sqft = [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000];
const starting_sqft = [
  1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000,
];
const base_sqft = [{ value: -1, label: "any" }];
const createSqft = (end = []) => {
  return [...starting_sqft, ...end];
};
const min_sqft = createSqft([]);
// const max_sqft = createSqft([9000, 10000]);
const max_sqft = createSqft([]);

export const sqft_min_options = [
  ...base_sqft,
  ...min_sqft.map((n, index) => ({
    label: index === min_sqft.length - 1 ? `${n}+` : `${n}`,
    value: n,
  })),
];
export const sqft_max_options = [
  ...base_sqft,
  ...max_sqft.map((n, index) => ({
    label: index === max_sqft.length - 1 ? `${n}+` : `${n}`,
    value: n,
  })),
];
export const max_wailist_options = createPrices([
  6000, 6500, 7000, 7500, 8000, 8500,
]).map((n, i, self) => ({
  label: `$${i + 1 == self.length ? n + "+" : n}`,
  value: n,
}));
export const buildNestioUrl = (page, base) => {
  const isRes = base === "apartments";
  const layout = page?.layout
    ?.toLowerCase()
    ?.replace(/ /g, "-")
    ?.replace(/w\//g, "with");
  return `/${base}/${page?.building?.street_address
    ?.toLowerCase()
    ?.replace("street", "")
    .trim()
    .replace(/ /g, "-")}/${isRes ? `${layout}/` : ""}${page?.unit_number}`;
};
// for form options in Incentive Page
export const eligibleOption = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
  {
    value: "Unsure",
    label: "Unsure",
  },
];
export const usageOption = [
  {
    value: "Office",
    label: "Office",
  },
  {
    value: "Industrial",
    label: "Industrial",
  },
  {
    value: "Retail",
    label: "Retail",
  },
  {
    value: "Hotel",
    label: "Hotel",
  },
];
export const commercial_referrer = [
  {
    value: "Google Search",
    label: "Google Search",
  },
  {
    value: "Personal Referral",
    label: "Personal Referral",
  },
  {
    value: "LinkedIn",
    label: "LinkedIn",
  },
  {
    value: "Display Ad",
    label: "Display Ad",
  },
  {
    value: "Facebook",
    label: "Facebook",
  },
  {
    value: "Two Trees Website",
    label: "Two Trees Website",
  },
  {
    value: "Instagram",
    label: "Instagram",
  },
  {
    value: "Direct Mailer",
    label: "Direct Mailer",
  },
  {
    value: "Media Coverage",
    label: "Media Coverage",
  },
  {
    value: "Existing Relationship with Two Trees",
    label: "Existing Relationship with Two Trees",
  },
  {
    value: "Building Signage",
    label: "Building Signage",
  },
  {
    value: "Other",
    label: "Other",
  },
];
export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

//Form Helpers. Trying to organize the files a bit better
export const constructPostId = (id, selected, interest) => {
  const selectID = [];
  if (selected !== null) {
    selectID.push(id[selected]);
  }
  if (interest && interest.length > 0) {
    interest.forEach((ele) => {
      selectID.push(id[ele]);
    });
  }

  return [...new Set(selectID.flat().map((element) => element))];
};

export const formSpreeId = {
  DUMBO: ["xwobooox"],
  Williamsburg: ["xjvbennn", "xpdrddwx"],
  "Fort Greene / Downtown Brooklyn": ["modwddax"],
  "Brooklyn Heights / Cobble Hill": ["mboyookx"],
  "Hell's Kitchen": ["xvaraakm"],
  "One Domino Square - Condominiums": ["xqkroakb"], //This and the next are buildings instead of neighborhoods due to clients awkward requirements
  "One Domino Square - Rentals": ["xqkroakb"],
  "One Domino Square": ["xqkroakb"],
};

export const forceFocus = (id, event, key) => {
  if (event.key === key) document.getElementById(id).focus();
};

// export const nestioGroupIds = (building) => {
//   return {
//     DUMBO: building === "60 Water Street Apartments" ? 61 : 60,
//     Williamsburg: building === "One South First Apartments" ? 596 : 119,
//     "Fort Greene / Downtown Brooklyn": 1,
//     "Brooklyn Heights / Cobble Hill": 62,
//     "Hell's Kitchen": 63,
//   };
// };

/**
 * Convert an Array of Objects to an Object, using `key` for Object keys
 // ! NOTE: This is only to be used with UNIQUE keys
 * EX: [{name:"john", age:}]
 */
/**
 * Converts an Array of objects to an Object with the specificed `key` indices.
 * Provides massive performance gains when doing lookups as opposed to Array.find()
 * @param  {array} array The Array of Objects
 * @param  {string} key   The key to use for each object in the object
 * @return {object} The converted Array > Object w/ indices
 * {@link http://jsben.ch/U4Cad Performance Test}
 * @example:
 *   var array = [{id:7384, name:"bob"}, {id:2930, name:"jane"}]
 *   var arrayToObjectWithKeys = arrayToObjectKeys(array, "id");
 *   - the result
 *      {7384:{id:7384, name:"bob"}, 2930:{id:2930, name:"jane"}}
 *   - now we can use key lookup
 *      arrayToObjectWithKeys[2910]
 *   - instead of the much slower Array.find() lookup
 *      array.find(obj => obj.id == 2930)
 */
export const convertArrayToObject = (array, key) => {
  // the initial accumulator is the EMPTY object
  return array.reduce((accumulator, currentItem) => {
    // create a key for each object
    accumulator[currentItem[key]] = currentItem;
    // pushes to initial object
    return accumulator;
    // initial accumulator: `{}`
  }, {});
};

export function cleanObject(obj) {
  if (Array.isArray(obj)) {
    return obj.map(cleanObject);
  }

  for (let key in obj) {
    if (obj[key] === null || obj[key] === "") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      obj[key] = cleanObject(obj[key]);
    }
  }
  return obj;
}

export const convertTime = (time) => {
  let hour = "";
  if (Number(time.slice(0, 2)) === 12) {
    return (hour = time + " pm");
  } else if (Number(time.slice(0, 2)) > 11) {
    return (hour =
      (Number(time.slice(0, 2)) % 12) + ":" + time.slice(3, 5) + " pm");
  } else if (Number(time.slice(0, 2)) < 12) {
    hour = time + " am";
  }
  return hour;
};

export const constructLayout = (layoutData, layoutsMap) => {
  const constructedLayout = [];
  layoutData.forEach((layout) => {
    constructedLayout.push(layoutsMap[layout]);
  });
  constructedLayout.filter((layout) => layout !== undefined || layout !== null);
  return [...new Set(constructedLayout.map((element) => element).flat(2))];
};

export const constructString = (data) => {
  let string = "";
  data.forEach((element, index) => {
    if (index !== data.length - 1) {
      string += element + ", ";
    } else {
      string += element;
    }
  });
  return string;
};

export const layoutsMap = {
  Studio: "studio",
  "Alcove Studio": "studio",
  "One Bedroom": "1br",
  "1 Bedroom": "1br",
  "One Bedroom w/ Home Office": ["1br", "2br"],
  "Two Bedroom": "2br",
  "2 Bedroom": "2br",
  "3 Bedroom": "3br",
};

export const returnNestioGroupIds = (isOne) => {
  const ids = {
    DUMBO: 2,
    Williamsburg: isOne ? 596 : 119,
    "Fort Greene / Downtown Brooklyn": 1,
    "Brooklyn Heights / Cobble Hill": 5322,
    "Hell's Kitchen": 5,
    "Mercedes House": 5,
  };
  return ids;
};
