import { useEffect, useState } from "react";
import {
  SubmitInput,
  FormProvider,
  SelectInput,
  StandardInput,
  CheckboxInput,
  DayPickerInput,
  TextareaInput,
} from "../../form";
import styles from "../../../styles/partials/forms/residentialRental-form.scss";
import {
  _classes,
  price_max_options,
  constructPostId,
  constructLayout,
  constructString,
  formSpreeId,
  cleanObject,
} from "../../utilities/helpers";
import { useAppState } from "../../providers";
import axios from "axios";
import {
  // nestioGroupIds,
  layoutsMap,
  getNeighborhoodProperties,
} from "../../utilities/formHelpers";
import moment from "moment";
const cl = _classes(styles);
ResidentialRentalForm.propTypes = {};

export default function ResidentialRentalForm() {
  const [layoutData, setLayoutData] = useState([]);
  const [interest, setInterest] = useState([]);
  const { byTemplate, useRef_contactform , getNestioDataByPath } = useAppState();
  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  // const [buildings, setBuildings] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [thirdPartySuccess, setThirdPartySuccess] = useState(false);
  const [formSpreeSuccess, setformSpreeSuccess] = useState(false);
  const [nestioSuccess, setNestioSuccess] = useState(false);
  const [isBroker, setIsBroker] = useState(false);
  const [group, setGroup] = useState({
    id: "",
    name: "",
  });

  //This shouldn't change so this should be fine, until it isn't.
  const oneDominoSquareID = "5225";
  const isOneDominoSquare = group?.id.includes(oneDominoSquareID);

  const price_max_options_revised = () => {
    if (!isOneDominoSquare) return price_max_options;
    //The client wants the options to be different, if one domino square is selected, we need to remove the first item and revise the value of the second to be 4495 instead of 4500.
    const newOptions = [...price_max_options];
    const newOption = { label: "$4495", value: 4495 };
    newOptions.splice(0, 4, newOption);

    return newOptions;
  };

  //Get all neighborhood pages and remove the office spaces and we are cleaning it up to remove null and empty values
  const allNeighborhoods = cleanObject(byTemplate("neighborhood"))?.filter(
    (building) => !building.path.includes("/office-spaces/")
  );

  //Same above just with the individual building pages
  const allProperties = cleanObject(byTemplate("building"))?.filter(
    (building) => !building.path.includes("/office-spaces/")
  );

  const neighborhood_options = allNeighborhoods.map((option) => ({
    label: option.h1,
    value: option.h1,
    properties: getNeighborhoodProperties(option, allProperties),
  }));

  useEffect(() => {
    if (nestioSuccess && formSpreeSuccess) {
      setThirdPartySuccess(true);
      setFormSubmitted(true);
    }
  }, [formSpreeSuccess, nestioSuccess]);
  useEffect(() => {
    setTimeout(() => {
      thirdPartySuccess === true && setThirdPartySuccess(false);
    }, 3500);
  }, [thirdPartySuccess]);

  const onlyOneProperty = selectedNeighborhood?.properties?.length === 1;

  useEffect(() => {
    if (onlyOneProperty) {
      const singleProperty = selectedNeighborhood?.properties[0];
      setGroup({ id: singleProperty?.map_link, name: singleProperty?.h1 });
    } else {
      setGroup({ id: "", name: "" });
    }
  }, [selectedNeighborhood]);

  const constructPostIdforNestio = (selectedNeighborhood, interests) => {
    const selectID = [];
    if (selectedNeighborhood === null) return;
    group?.id && selectID.push(group.id);
    if (interests && interests.length > 0) {
      interests.forEach((ele) => {
        const property = allNeighborhoods.find((neighborhood) => {
          return neighborhood.h1 === ele;
        });

        selectID.push(property.h6);
      });
    }

    return [...new Set(selectID.flat().map((element) => element))];
  };

  const toggleLayout = (value) =>
    setLayoutData((old) =>
      old.includes(value) ? old.filter((i) => i !== value) : [...old, value]
    );

  const toggleInterest = (value) =>
    setInterest((old) =>
      old.includes(value) ? old.filter((i) => i !== value) : [...old, value]
    );
  // console.log("FormSpreeID", formSpreeId);
  // console.log("selectedBuilding", selectedBuilding);

  // console.log("isOneDominoSquare", isOneDominoSquare);
  const handleSubmit = async (formData) => {
    // const isOneDominoSquare = selectedBuilding?.includes("One Domino Square");
    const dataArray = constructPostId(
      formSpreeId,
      isOneDominoSquare ? selectedBuilding : selectedNeighborhood?.label,
      interest
    );
    // console.log("DataArray", dataArray);
    const {unit_number , private: isPrivate} = getNestioDataByPath()

    //NOTE: Formspreee posts
    dataArray.forEach((formSpree) => {
      const formSpreeFormData = {
        source: window.location.href,
        campaign_info: "",
        neighborhood: selectedNeighborhood?.label,
        nestio_group_id: group.id,
        property: group.name,
        full_name: formData.full_name,
        email: formData.email,
        phone: formData.phone_1,
        move_in_date: moment(formData.move_in_date).format("DD/MM/YYYY"),
        max_budget: isNaN(formData.max_price)
          ? ""
          : Number(formData.max_price).toFixed(0),
        preferred_floor_plan: constructString(layoutData),
        is_broker: formData.is_broker,
        broker: formData.broker,
        message:
          formData.notes !== ""
            ? formData.notes
            : `I'm interested in Two Trees`,
        other_neighborhoods: constructString(interest),
        use_property_contact: true,
        unitNumber: unit_number,
        private: isPrivate
      };
      try {
        /// req goes here
        axios({
          url: `https://formspree.io/${formSpree}`,
          method: "POST",
          crossDomain: true,
          data: formSpreeFormData,
          dataType: "json",
        }).then((response) => {
          // Success 🎉
          if (response.status === 200 || response.status === 201) {
            setformSpreeSuccess(true);
          }
        });
      } catch (e) {
        console.log("error", e);
      }
    });

    const nestioIds = constructPostIdforNestio(selectedNeighborhood, interest);
    // const allNestioIds = [];
    nestioIds?.forEach(() => {
      // each id needs to have a post request
      //TODO: Make sure to add the other neighborhood that has been selected as an interest.
      const nestioData = {
        client: {
          people: [
            {
              first_name: formData.full_name,
              last_name: "",
              email: formData.email,
              phone_1: formData.phone_1,
            },
          ],
          group: group.id,
          layout: constructLayout(layoutData, layoutsMap),
          price_ceiling: isNaN(formData.max_price)
            ? ""
            : Number(formData.max_price).toFixed(2),
          move_in_date: moment(formData.move_in_date).format("YYYY-MM-DD"),
          notes: `Neighborhood: ${selectedNeighborhood?.label}\nProperty: ${
            group.name
          }\nOther Neighborhoods: ${constructString(
            interest
          )}\nLayouts: ${constructString(layoutData)}\nUser comments: ${
            formData.notes !== ""
              ? formData.notes
              : "I'm interested in Two Trees"
          }`,
          campaign_info: "",
          broker_company: null,
          client_referral: null,
          lead_source: "website",
          source_type: "organic",
          sms_opted_in: "marketing-enabled",
          is_broker: formData.is_broker,
          broker: formData.broker,
        },
      };
      // setNestioSuccess(false);
      // console.log("nestioData", nestioData);

      try {
        axios({
          method: "POST",
          url: "https://nestiolistings.com/api/v2/clients",
          headers: {
            Authorization:
              "Basic " + window.btoa("0c44cf1104174061ad121134a3415451:"),
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          data: JSON.stringify(nestioData),
          dataType: "json",
        }).then((response) => {
          // Success 🎉
          if (response.status === 201) {
            setNestioSuccess(true);
            setIsBroker(false);
          }
        });
      } catch (e) {
        console.log("error", e);
        setFormSubmitted(false);
      }
    });
  };
  const handleScroll = (useRef_contactform) => {
    if (useRef_contactform.scrollTop !== 0) {
      useRef_contactform.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const renderThankYou = () => {
    if (formSubmitted) {
      handleScroll(useRef_contactform);
      return (
        <div
          id="residential-form-thank-you"
          className={cl(["form__thank_you", "thank_you_message_tracker"])}
        >
          Thank you! A Leasing Associate will be in touch with you shortly!
        </div>
      );
    }
  };
  const makeBuildingOptions = (properties) => {
    const options = properties?.map((property) => ({
      label: property?.h1,
      buildingId: property?.map_link,
      value: property?.h1,
    }));
    return options;
  };
  return (
    <div className={cl("residential_rental_form")}>
      <FormProvider
        onSubmit={handleSubmit}
        additionalData={{
          layout: layoutData,
        }}
        thankyouMessage={() => renderThankYou()}
        type={"residential_rental_form"}
        thirdPartySuccess={thirdPartySuccess}
      >
        <SelectInput
          label={"What neighborhood are you interested in?"}
          placeholder={"Which Neighborhood?"}
          name="group"
          onSelect={(e) => {
            // console.log("e", e);
            setSelectedNeighborhood(e);
          }}
          options={neighborhood_options}
          className={cl("residential_rental_select_neighborhood")}
          rules={{ required: "Neighborhood is required" }}
        />

        {!onlyOneProperty && (
          <SelectInput
            label={"What building are you interested in?"}
            name="group1"
            onSelect={(e) => {
              // console.log("e", e.h1, e);
              setSelectedBuilding(e.label);
              setGroup({ id: e.buildingId, name: e.label });
            }}
            options={makeBuildingOptions(selectedNeighborhood?.properties)}
            className={cl("residential_rental_select_neighborhood")}
            placeholder={"Building"}
            rules={{ required: "Building is required" }}
          />
        )}

        <StandardInput
          className={cl("residential_rental_standard")}
          label={""}
          name="full_name"
          placeholder="Full Name"
          rules={{ required: "Please enter your full name." }}
        />
        <StandardInput
          label={""}
          className={cl("residential_rental_standard")}
          name="email"
          placeholder="Email"
          rules={{
            required: "Please enter a valid email.",
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          }}
        />
        <StandardInput
          label={""}
          className={cl("residential_rental_standard")}
          placeholder="Phone Number"
          name="phone_1"
          rules={{ required: "Please enter a valid phone number." }}
        />
        <DayPickerInput
          placeholder="Move In Date"
          name="move_in_date"
          rules={{ required: "Move In Date is required" }}
        />
        <SelectInput
          key={selectedBuilding}
          name="max_price"
          options={price_max_options_revised()}
          className={cl("residential_rental_select")}
          placeholder="Max Budget (Per Month)"
          rules={{ required: "Max Budget is required" }}
        />
        <div className={cl("checkboxs_wrapper")}>
          <div className={cl("checkboxs_field_title")}>
            {" "}
            PREFERRED FLOORPLAN(S){" "}
          </div>
          <div className={cl("checkbox_option_wrapper_layout")}>
            <CheckboxInput
              name="Studio"
              className={cl("control")}
              label={"Studio"}
              value={"Studio"}
              inputChange={() => toggleLayout("Studio")}
            />
            <CheckboxInput
              name="OneBr"
              className={cl("control")}
              label={"One Bedroom"}
              value={"One Bedroom"}
              inputChange={() => toggleLayout("One Bedroom")}
            />
            <CheckboxInput
              name="OneBrOffice"
              className={cl("control")}
              label={"One Bedroom w/ Home Office"}
              value={"One Bedroom w/ Home Office"}
              inputChange={() => toggleLayout("One Bedroom w/ Home Office")}
            />
            <CheckboxInput
              name="TwoBr"
              className={cl("control")}
              label={"Two Bedroom"}
              value={"Two Bedroom"}
              inputChange={() => toggleLayout("Two Bedroom")}
            />
          </div>
        </div>

        <TextareaInput
          label={""}
          className={cl("residential_rental_message")}
          name="notes"
          placeholder="Message"
        />
        <div className={cl("checkboxs_wrapper")}>
          <div className={cl("checkboxs_field_title")}>
            {" "}
            ARE YOU INTERESTED IN OTHER NEIGHBORHOODS?{" "}
          </div>
          <div className={cl("checkbox_option_wrapper")}>
            <CheckboxInput
              name="DUMBO"
              className={cl("control")}
              label={"DUMBO"}
              value={"DUMBO"}
              inputChange={() => {
                toggleInterest("DUMBO");
              }}
            />
            <CheckboxInput
              name="Williamsburg"
              className={cl("control")}
              label={"Williamsburg"}
              value={"Williamsburg"}
              inputChange={() => {
                toggleInterest("Williamsburg");
              }}
            />
            <CheckboxInput
              name="Fort Greene / Downtown Brooklyn"
              className={cl("control")}
              label={"Fort Greene / Downtown Brooklyn"}
              value={"Fort Greene / Downtown Brooklyn"}
              inputChange={() => {
                toggleInterest("Fort Greene / Downtown Brooklyn");
              }}
            />
            <CheckboxInput
              name="Brooklyn Heights / Cobble Hill"
              className={cl("control")}
              label={"Brooklyn Heights / Cobble Hill"}
              value={"Brooklyn Heights / Cobble Hill"}
              inputChange={() => {
                toggleInterest("Brooklyn Heights / Cobble Hill");
              }}
            />
            <CheckboxInput
              name="Hell's Kitchen"
              className={cl("control")}
              label={"Hell's Kitchen"}
              value={"Hell's Kitchen"}
              inputChange={() => {
                toggleInterest("Hell's Kitchen");
              }}
            />
          </div>
        </div>
        <div className={cl("broker")}>
          <fieldset className={cl("checkboxs_wrapper")}>
            <legend className={cl("broker_title")}>Are you a broker?</legend>
            <div
              className={cl([
                "checkbox_option_wrapper_layout",
                "broker_checkbox",
              ])}
            >
              <CheckboxInput
                name="is_broker"
                className={cl("control")}
                label={"Yes"}
                value={"yes"}
                inputChange={(e) => setIsBroker(e.target.checked)}
              />
            </div>
          </fieldset>
          {isBroker && (
            <StandardInput
              label={""}
              className={cl("residential_rental_standard")}
              name="broker"
              placeholder="Brokerage Name"
              rules={{ required: "Please enter your brokerage name" }}
            />
          )}
        </div>
        <p className={cl("disclaimer")}>
          By selecting Submit, you agree that Two Trees and its affiliates may
          call/text you about your inquiry, which may involve use of automated
          means and prerecorded/artificial voices. You don&apos;t need to
          consent as a condition of buying any property, goods, or services.
          Message/data rates may apply.
        </p>
        <SubmitInput
          text={"Submit"}
          className={cl("residential_rental_submit_field")}
        />
      </FormProvider>
    </div>
  );
}
